<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container card">
          <!-- CABECERA TOTALES -->
          <div class="row">
            <div class="col d-flex justify-content-start">
              <h4>
                Metodos de pago
              </h4>
            </div>
          </div>
          <b-row class="modal-header">
            <b-col cols="md-12">
            <slot name="header">
              <div v-if="limited">
                <b-row v-if="( (header.pos!=0) && (total>=208644) && ( (header.letter=='C') || (header.letter=='B')) )">
                  <div class="col-md-12 text-center">
                    <div class="alert alert-danger" role="alert">
                      <h4>
                        ¡Record&aacute; que para realizar una factura igual o mayor a $ 208.644 ten&eacute;s que informar a la AFIP todos los datos del cliente!
                      </h4>
                    </div>
                  </div>
                </b-row>
              </div>

              <b-row>
                <b-col cols="md-6 text-left">
                  <h3 class="font-weight-bolder">
                    Total: $ {{ round(total,2) }}
                  </h3>
                </b-col>
                <b-col cols="md-6 pull-right">
                  <b-button v-if='limited' class="modal-default-button" variant="light" size="lg" @click="modified">
                    Modificar
                  </b-button>
                </b-col>
              </b-row>
              
              <b-row>
                <b-col cols="md-6 text-left">
                  <ul class="list-unstyled">
                    <li><h5>Efectivo: ${{ payarray.cash }}</h5></li>
                    <li><h5>Transferencia: ${{ totalTransfer }}</h5></li>
                    <li v-for="tpcheck in payarray.tpchecks" :key="tpcheck.number">
                      Cheque - Numero: <b>{{tpcheck.number}}</b> / Monto: <b>${{ round(tpcheck.amount,2) }}</b>
                    </li>
                    <li v-for="creditcard in payarray.creditcards" :key="creditcard.number">
                      {{creditcard.name}} - Cupon: <b>{{creditcard.coupon}}</b> / Monto <b>${{ round(creditcard.amount,2) }}</b>
                    </li>
                    <li v-for="cc in payarray.accounts" :key="cc.amount">
                      Cuenta corriente - Monto: <b> ${{cc.amount}}</b> / Vencimiento: <b>{{ cc.days }}</b>
                    </li>
                  </ul>
                </b-col>
              </b-row>

              <b-col cols="md-3 text-left">
              <b-row>
                  <div v-if="limited">
                    <h5 v-if="change>0">Vuelto: $ {{ round(change,2) }}</h5>
                    <h5 v-if="diference!=0">Diferencia: $ {{ round(diference,2) }}</h5>
                  </div>
                </b-row>
              </b-col>

            </slot>
            </b-col>
          </b-row>
          
          <b-col class="card-body">
            <b-row>
              <b-col cols="md-4">
                <b-form-group class="col-border">
                  <label>Efectivo</label>
                  <input v-model.number="cash" type="number" min="0" step="0.01" class="form-control">
                </b-form-group>
                <b-form-group class="col-border">
                  <label>Tranferencia</label>
                  <input v-model.number="transfer" type="number" min="0" step="0.01" class="form-control" @input="updateBanks">
                </b-form-group>
              </b-col>
              <b-col cols="md-5">
                <b-form-group>
                  <label>Caja destino</label>
                  <select v-model="payarray.fund_id" class="form-control" name="fund_id" style="width: 94%;">
                    <option v-for="fund in funds" :key='fund.id' :value="fund.id">{{fund.name}}</option>
                  </select>
                </b-form-group>
                <b-form-group>
                  <label>Caja destino</label>
                    <div class="d-flex align-items-center">
                      <select v-model="fundTransfer" class="form-control" name="fund_id" @change="updateBanks">
                        <option v-for="fundBank in fundsBank" :key='fundBank.id' :value="fundBank.id">{{fundBank.name}}</option>
                      </select>
                      <b-button @click="addInputSelect" class="ml-4">+</b-button>
                    </div>
                </b-form-group>
              </b-col>
              <b-col cols="md-4">
                <div v-for="(inputSelect, index) in inputSelects" :key="index" class="mt-4">
                  <b-form-group>
                    <input v-model.number="inputSelect.input" type="number" min="0" step="0.01" class="form-control" placeholder="0" @input="updateBanks">
                  </b-form-group>
                </div>
              </b-col>
              <b-col cols="md-5">
                <div v-for="(inputSelect, index) in inputSelects" :key="index" class="mt-4" style="width: 94%;">
                  <b-form-group>
                    <select v-model="inputSelect.select" class="form-control" name="fund_id" @change="updateBanks">
                      <option v-for="fundBank in fundsBank" :key='fundBank.id' :value="fundBank.id">{{fundBank.name}}</option>
                    </select>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>

            <b-row v-if="showCreditCard" class="mt-4">
              <b-form-group>
                <credit-card v-on:creditcardcancel="showCreditCard=false" v-on:creditcardsaved="creditCardSave"/>
              </b-form-group>
            </b-row>

            <b-row v-if="showTpCheck">
              <tp-check v-on:tpcheckcancel="showTpCheck=false" v-on:tpchecksaved="tpCheckSave"/>
            </b-row>
            <b-row v-if="showCc">
              <client-account v-on:clientAccountSaved="clientAccountSaved" v-on:clientAccountCancel="showCc=false" :total="ccTotal"/>
            </b-row>
          
          </b-col>

          <div class="modal-footer">
       
              <b-button variant="light" size="lg" class="mr-2" v-if="paymentMethods.cuenta_corriente==1" @click="showCc=!showCc">Cuenta corriente</b-button>
              <b-button variant="light" size="lg" class="mr-2" v-if="paymentMethods.tarjetas==1 && genType!='NC'" @click="showCreditCard=!showCreditCard">Tarjetas</b-button>
              <b-button variant="light" size="lg" class="mr-2" v-if="paymentMethods.cheques==1 && genType!='NC'" @click="showTpCheck=!showTpCheck">Cheques</b-button>
         
           
              <b-button class="modal-default-button ml-5" variant="orange" size="lg" v-if="(round(diference,2)==0) || (limited==0)" @click="finishReceipt">Finalizar venta</b-button>
            
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>
import creditCard from './Credit-card.vue';
import clientAccount from './Client-account.vue';
import tpCheck from './Tp-check.vue';

export default {
  props: ['total', 'paymentMethods', 'header', 'limited', 'fundDefault', 'genType'],
  data: function() {
    return {
      showCreditCard: false,
      showTpCheck: false,
      showCc: false,
      totalpayed: 0,
      diference: 0,
      change: 0,
      funds: [],
      fundsBank: [],
      payarray: {
        banks:[],
        cash: 0,
        fund_id: 0,
        fundBanks_id: [],
        accounts: [],
        creditcards: [],
        tpchecks: []
      },
      limitcc: null,
      ccTotal: 0,
      inputSelects: [],
      transfer: 0,
      fundTransfer: 0,
      totalTransfer: 0,
    }
  },
  mounted: function () {
    this.payarray.fund_id = this.fundDefault;
    if (this.header.cc_only == 1){
      this.ccTotal = this.total;
      this.showCc = true;
      this.payarray.cash = 0;
    }else{
      this.transfer = this.total;
    }
    
    this.getFunds();
    this.getLimitCC();
    this.calc();
  },
  computed: {
      cash: {
            get: function () {
                return this.payarray.cash;
            },
            set: function (value) {
                if (value==''){
                    this.payarray.cash=0;
                }else{
                    this.payarray.cash=value;
                }
            }
        },
      banks: {
          get: function () {
              return this.payarray.banks;
          },
          set: function (value) {
              if (value==''){
                  this.transfer = 0;
              }else{
                  this.transfer = value;
              }
          }
      },
  },
  watch: {
    payarray: {
      handler: function () {
        this.calc();
      }, deep: true
    }
  },
  methods: {
    round: function(value, decimals) {
      return (Number(Math.round(value + 'e' + decimals) + 'e-' + decimals));
    },
    updateBanks() {
      const banks = [{ amount: this.transfer }];
      const fundTransfer = [{fund: this.fundTransfer}];
      const inputSelectsBanks = this.inputSelects.map(select => ({
        amount: select.input,
      }));

      const selectSelectsBanks = this.inputSelects.map(select => ({
        fund: select.select,
      }));
      this.payarray.fundBanks_id = fundTransfer.concat(selectSelectsBanks);
      this.payarray.banks = banks.concat(inputSelectsBanks);
      console.log(this.payarray.fundBanks_id);
      this.calcTotalTransfer();
    },
    addInputSelect() {
      this.inputSelects.push({ input: 0, select: '' });
    },
    calcTotalTransfer() {
      this.totalTransfer = this.payarray.banks.reduce((sum, bank) => sum + bank.amount, 0);
    },
    calc: function () {
      this.totalpayed = 0;

      var interestCreditCards = this.payarray.creditcards.map(el => parseFloat(el.interest)).reduce((acc, currentValue) => acc + currentValue, 0);
      var totalCreditCards = this.payarray.creditcards.map(el => el.amount).reduce((acc, currentValue) => acc + currentValue, 0);
      var totalTpChecks = this.payarray.tpchecks.map(el => el.amount).reduce((acc, currentValue) => acc + currentValue, 0);
      var totalAccounts = this.payarray.accounts.map(el => el.amount).reduce((acc, currentValue) => acc + currentValue, 0);

      var withoutcash = this.round(totalCreditCards,2) + this.round(interestCreditCards,2) + this.round(totalTpChecks,2) + this.round(totalAccounts,2);

      var cosa = this.total - withoutcash;
      
      if(this.payarray.cash==''){
        this.payarray.cash = 0;
      }

      var totalcash = this.payarray.cash;
      var totalbanks = this.totalTransfer;      

      this.change = totalcash - totalbanks - cosa;
      
      this.totalpayed = this.round(totalcash,2) + this.round(withoutcash,2) + this.round(totalbanks,2);
      this.diference = this.round(this.total,2) - this.round(this.totalpayed,2);
    },
    creditCardSave: function(obj) {
      console.log(obj)
      this.payarray.creditcards.push(obj);
      this.showCreditCard=false;
      this.$emit('paymentmethodschanged',this.payarray);
    },
    tpCheckSave: function(obj) {
      this.payarray.tpchecks.push(obj);
      this.showTpCheck=false;
    },
    fromdateUpdated: function (date) {
      this.header.service_fromdate = date;
    },
    clientAccountSaved: function (obj) {
      this.payarray.accounts.push(obj);
      this.showCc=false;
    },
    modified: function () {
      this.$emit('paymentmethodschanged',{});
      this.$emit('close');
    },
    finishReceipt: function () {
      this.$emit('paymentmethodsclose',this.payarray);
      this.$emit('close');
    },
    getFunds() {
      var vm = this;
      window.axios.get('/api/funds/list')
      .then(response => {
        if(response.data) {
          const allFunds = response.data.data.filter(fund => fund.type != "Tarjeta de Credito");
          vm.funds = allFunds.filter(fund => fund.type === 'Efectivo');
          vm.fundsBank = allFunds.filter(fund => fund.type === 'Banco');
        }
      })
      .catch(error => {
        alert(error);
      })
    },
    getLimitCC() {
      window.axios.get('/api/clients/getLimitClientAccount/'+this.header.client_id)
            .then(response => {
              if(response.data) {
                if(response.data.data < 0) {
                  this.limitcc = 0;
                } else {
                  this.limitcc = response.data.data;
                }
              }
            })
            .catch(error => {
              alert(error);
            })
    }
  },
  components: {
    clientAccount,
    tpCheck,
    creditCard
  }
}
</script>

<style scoped>

  .modal-mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }
  
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  
  .modal-container {
    width: 97%;
    height: 97%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0; /* Asegúrate de no tener margen automático aquí */
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    overflow-y: auto;
}

  
  .modal-default-button {
    float: right;
    margin-left: 10px;
  }
  
  
  .modal-enter {
    opacity: 0;
  }
  
  .modal-leave-active {
    opacity: 0;
  }
  
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>